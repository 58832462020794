<template>
  <div>
    <div class="questionDescription">
      <p>{{ index }}、{{ question.description }}</p>
    </div>
    <div class="answer">
      <el-form :model="form" v-for="(blank,i) in question.blanks" :key="i">
        <el-form-item>
          第{{ i + 1 }}空:
          <el-input type="text" v-model="form.desc[i]" @change="checkAnswer(blank,form.desc[i])"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Blank',
  props: {
    question: {},
    index: Number
  },
  data () {
    return {
      form: {
        desc: []
      },
      blankAnswerList: []
    }
  },
  created () {
    this.initBlankAnswerList()
  },
  methods: {
    initBlankAnswerList () {
      this.question.blanks.forEach(blank => {
        this.blankAnswerList.push({
          blankId: blank.id,
          record: false
        })
      })
    },
    checkAnswer (blank, input) {
      let flag = true
      this.blankAnswerList.forEach(r => {
        if (r.blankId === blank.id) {
          r.record = input === blank.answer
        }
        if (!r.record) {
          flag = false
        }
      })
      this.putRecord(flag)
    },
    putRecord (flag) {
      this.$emit('putAnswer', {
        questionId: this.question.id,
        answer: flag
      })
    }
  }
}
</script>

<style scoped>

</style>
