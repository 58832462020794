<template>
  <div>
    <div class="questionDescription">
      <p>{{ index }}、{{ question.description }}</p>
    </div>
    <div class="optionsDescription">
      <el-form :model="form">
        <el-radio-group v-model="form.resource" v-for="option in question.options" :key="option.id">
          <el-radio @change="uploadAnswer(option.answer)" :label="option.description" v-model="option.id"></el-radio>
          <br><br>
        </el-radio-group>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Single',
  props: {
    question: {},
    index: Number
  },
  data () {
    return {
      form: {}
    }
  },
  methods: {
    uploadAnswer (answer) {
      this.$emit('putAnswer', {
        questionId: this.question.id,
        answer: answer
      })
    }
  }
}
</script>

<style scoped>

</style>
