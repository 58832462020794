<template>
  <div>
    <div class="questionDescription">
      <p>{{ index }}、{{ question.description }}</p>
    </div>
    <div class="optionDescription">
      <el-checkbox-group v-model="checkList" @change="checkAnswer">
        <el-checkbox v-for="opt in question.options" :key="opt.id" :label="opt.description" :value="opt.id"
                     @change="checkAnswer(opt.id,opt.answer)"></el-checkbox>
        <br>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Multiple',
  props: {
    question: {},
    index: Number
  },
  data () {
    return {
      checkList: []
    }
  },
  created () {
    this.initCheckList()
  },
  methods: {
    initCheckList () {
      this.question.options.forEach(o => {
        this.checkList.push({
          id: o.id,
          answer: false
        })
      })
    },
    checkAnswer (id, answer) {
      this.checkList.forEach(o => {
        if (o.id === id) {
          o.answer = answer
        }
      })
      let flag = true
      this.question.options.forEach(o => {
        this.checkList.forEach(c => {
          if (o.id === c.id) {
            if (o.answer !== c.answer) {
              flag = false
            }
          }
        })
      })
      this.$emit('putAnswer', {
        questionId: this.question.id,
        answer: flag
      })
    }
  }
}
</script>

<style scoped>

</style>
