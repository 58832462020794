<template>
  <div class="courseStudy">
    <div class="container">
      <div class="courseTitle">
        <span style="font-size: 20px">{{ courseInfo.name }}</span>
        {{ d_options.video.url }}
      </div>
      <div v-show="hasVideo" class="videoSection">
        <d-player id="player" ref="dplayer" @ended="finishStudy()" :options="d_options"
                  style="width: 816px;height: 459px;"></d-player>
      </div>
      <div v-show="hasHtml">
        <iframe :src="htmlUrl" frameborder="0" width="100%"></iframe>
      </div>
      <div v-show="hasQuiz">
        <div class="question" v-for="(question,i) in quiz" :key="question.id">
          <Single @putAnswer="listChange" v-if="question.mode==='single'" :question="question" :index="i+1"></Single>
          <Multiple @putAnswer="listChange" v-else-if="question.mode==='multiple'" :question="question"
                    :index="i+1"></Multiple>
          <Blank @putAnswer="listChange" v-else-if="question.mode==='completion'" :question="question"
                 :index="i+1"></Blank>
        </div>
        <div>
          <el-button type="primary" style="width: 100px;" @click="checkAnswer">提交</el-button>
        </div>
      </div>
      <div class="courseInfo">
      </div>
    </div>
  </div>
</template>

<script>
import VueDPlayer from 'vue-dplayer'
import 'vue-dplayer/dist/vue-dplayer.css'
import Vue from 'vue'
import axios from 'axios'
import Single from '@/components/Questions/Single'
import Multiple from '@/components/Questions/Multiple'
import Blank from '@/components/Questions/Blank'

Vue.use(VueDPlayer)

export default {
  name: 'CourseStudy',
  props: {
    courseInfo: Object
  },
  components: {
    Single,
    Multiple,
    Blank
  },
  data () {
    return {
      hasVideo: false,
      hasHtml: false,
      htmlUrl: '',
      hasQuiz: false,
      quiz: [],
      d_options: {
        container: document.getElementById('d-player'),
        video: {
          url: ''
        }
      },
      answerList: []
    }
  },
  watch: {
    courseInfo: {
      deep: true,
      handler (val, old) {
        this.$refs.dplayer.dp.switchVideo({ url: val.chapterInfo.content })
        if (val.chapterInfo.contentType === 'video') {
          this.hasVideo = true
        } else {
          this.hasVideo = false
        }
        if (val.chapterInfo.contentType === 'html') {
          this.hasHtml = true
          this.htmlUrl = val.chapterInfo.content
        } else {
          this.htmlUrl = false
        }
        if (val.chapterInfo.contentType === 'quiz') {
          this.hasQuiz = true
          this.getQuestions(val.id)
        } else {
          this.hasQuiz = false
        }
      }
    }
  },
  methods: {
    listChange (data) {
      const questionId = data.questionId
      this.answerList.forEach(record => {
        if (record.id === questionId) {
          record.answer = data.answer
        }
      })
    },
    finishStudy () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/record/finishCheckpoint',
        params: {
          chapterId: this.courseInfo.chapterInfo.chapterId,
          termId: this.$route.params.termId
        }
      }).then(response => {
        if (response.status) {
          if (response.data.status) {
            this.$message.success(response.data.message)
            this.$emit('finished')
          } else {
            this.$message.error(response.data.message)
          }
        } else {
          this.$message.error('未知异常,请检查网络')
        }
      })
    },
    getQuestions (chapterId) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/question/showQuestionsByChapterId',
        params: {
          chapterId: chapterId,
          setOptionsAndBlanks: true
        }
      }).then(res => {
        if (res.status) {
          if (res.data.status) {
            this.quiz = res.data.data
            this.quiz.forEach(q => {
              this.answerList.push({
                id: q.id,
                answer: false
              })
            })
          }
        } else {
          this.$message.error('网络异常,请检查网络')
        }
      })
    },
    checkAnswer () {
      this.$confirm('确定提交?')
        .then(_ => {
          let flag = true
          this.answerList.forEach(answer => {
            flag = answer.answer
          })
          if (flag) {
            this.finishStudy()
          } else {
            this.$message.error('未能全部正确完成!请检查并修改!')
          }
        })
        .catch(_ => {
        })
    }
  }
}
</script>

<style scoped>
.courseStudy {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.courseTitle {
  margin-bottom: 20px;
}
</style>
